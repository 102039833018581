import React from 'react';
import styled from 'styled-components';
import heroBg from "../images/hero-bg.png";
import Section from './section';

const HeroStyle = styled(Section)`
  background-image: url(${heroBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  min-height: 500px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #fff;
`;
HeroStyle.defaultProps = {
  id: 'hero',
}

export default HeroStyle;

// export default class Hero extends React.Component<{},{}>{
//   render(){
//     return <HeroStyle id="hero">
//       {this.props.children}
//     </HeroStyle>;
//   }
// }
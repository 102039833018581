import React from 'react';
import styled from 'styled-components';

const SectionStyle = styled.section`
  text-align: center;
`;

type SectionProps = {
  className?:string;
  id:string;
}
export default class Section extends React.Component<SectionProps,{}>{
  render(){
    return <SectionStyle id={this.props.id} className={this.props.className}>
      <div className="container">
        {this.props.children}
      </div>
    </SectionStyle>;
  }
}

// export default function Section({id, children}){
//   return <section id={id}>
//     <div className="container">
//       {children}
//     </div>
//   </section>;
// }
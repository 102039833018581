import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.scss";

export const SiteName = () => {
  //Limitation: Only one useStaticQuery can be used per file
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
        }
      }
    }
  `);

  return <>{data.site.siteMetadata.name}</>;
};

// const Layout = ({ children })=>{
//   return <>
//     <Header />
//     <main id="main-content">{children}</main>
//     <footer id="footer">
//       <div className="container">
//         © {new Date().getFullYear()}, Built with{` `}<a href="https://www.gatsbyjs.com">Gatsby</a>
//       </div>
//     </footer>
//   </>;
// };
// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// export default Layout;

export default class Layout extends React.Component<{}, {}>{
  render(){
    return <>
      <Header />
      {/* <div><SiteName /></div> */}
      <main id="main-content">{this.props.children}</main>
      <footer id="footer">
        <div className="container">
          © {new Date().getFullYear()}, Built with{` `}<a href="https://www.gatsbyjs.com">Gatsby</a>
        </div>
      </footer>
    </>;
  }
};
import React, { Component } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
// import logoImg from "../images/cro-logo.png";

const Masthead = styled.header`
  position: relative;
  background-color: #399BFF;
  box-shadow: 0 4px 10px -10px rgb(0 0 0 / 60%);
  z-index: 2000;

  .object-fit-polyfill{
    position: static !important; //Fixes things on IE11. Not sure why this is necessary
  }

  .container{
    display: flex;
    height: 80px;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: space-evenly;

    > *{
      display: flex;
      align-items: center;
    }
  }
  #main-logo{
    padding-right: 15px;
    
    .logo{
      display: block;
      min-width: 100px;
    }
  }
`;

const MainMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;

  li{
    padding: 0 5px;
  }
  
  @media (min-width: 1024px){
    justify-content: center;
    li{
      padding: 0 14px;
    }
  }

  .menu-item-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &:hover{
      background-color: #2A86E6;
    }
  }

  .menu-item-button a{
    border: 1px solid #fff;
    padding: 10px;

    &:hover{
      background-color: #2A86E6;
    }
  }

  a{
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #ffffff;
    outline: 0;
    border: 0;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;
  }
`;

type HeaderProps = {
  // siteTitle: string;
}
type HeaderState = {}
export class Header extends Component<HeaderProps, HeaderState> {
  // static defaultProps:HeaderProps = {
  //   siteTitle: 'Hello everyone!'
  // }

  render() {
    return <Masthead id="masthead" className="navbar">
      <div className="container">
        <div id="main-logo" className="navbar-header style-light">
          {/* <a href="https://crometrics.com/"></a> */}
          <Link to="/">
            {/* loading="eager" fixes the FOUC issues seen between pages */}
            <StaticImage src="../images/cro-logo.png" alt="Cro Metrics logo" height={50} className="logo" placeholder="blurred" loading="eager"/>
            {/* <img src={logoImg} className="logo" alt="Cro Metrics logo" height={50}/> */}
          </Link>
        </div>
        <MainMenu id="menu-main-menu">
          <li className="menu-item-link">
            <a title="Services" href="https://crometrics.com/services/">Services</a>
          </li>
          <li className="menu-item-link">
            <a title="Clients" href="https://crometrics.com/clients/">Clients</a>
          </li>
          <li className="menu-item-link">
            <a title="Meet the Team" href="https://crometrics.com/meet-the-team/">Meet the Team</a>
          </li>
          <li className="menu-item-link">
            <a title="Culture" href="https://crometrics.com/culture/">Culture</a>
          </li>
          <li className="menu-item-link">
            <a title="Careers" href="https://crometrics.com/careers/">Careers</a>
          </li>
          <li className="menu-item-link">
            <a title="Articles" href="https://crometrics.com/articles/">Articles</a>
          </li>
          <li className="menu-item-link">
            <a title="Contact" href="https://crometrics.com/contact/">Contact</a>
          </li>
          <li className="menu-item-button">
            <a title="Client Login" target="_blank" href="https://my.crometrics.com">Client Login</a>
          </li>
        </MainMenu>
        <div id="menu-login">
        </div>
      </div>
    </Masthead>;
  }
}

export default Header;